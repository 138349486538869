const bodyScrollLock = require('body-scroll-lock'),
      disableBodyScroll = bodyScrollLock.disableBodyScroll,
      enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const init = ()=> {
        let data;
        const souvenir = document.querySelector('#souvenir'),
              item = document.querySelectorAll('.js-item');
        
        const getJSON = (url)=> {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            //xhr.responseType = 'json';
            xhr.onload = ()=> {
                if (xhr.status === 200) {
                    let json = xhr.response.replace(/(\r\n|\n|\r)/gm, "");
                    data = JSON.parse(json);
                } else {
                    console.log('error: ' + xhr.status);
                }
                //xhr.status === 200 ? data = xhr.response : console.log('error: ' + xhr.status);
            };
            xhr.send();
        };
        
        const clickOutside = function(e) {
            if (!e.target.closest('.c-souvenir')) {
                hideSouvenir();
                document.removeEventListener('click', clickOutside);
        	}
        };
        
        const hideSouvenir = ()=> {
            souvenir.classList.remove('is-visible');
            setTimeout(()=> {
                souvenir.classList.remove('is-block');
                enableBodyScroll(souvenir);
                flkty_big.destroy();
                flkty_thumbs.destroy();
                souvenir.querySelector('.js-souvenirBigCarousel').innerHTML = "";
                souvenir.querySelector('.js-souvenirThumbsCarousel').innerHTML = "";
                document.removeEventListener('click', clickOutside);
                
                document.querySelector('#souvenir').querySelector('.js-next').removeEventListener('click', next_souvenir);          
                document.querySelector('#souvenir').querySelector('.js-prev').removeEventListener('click', prev_souvenir);
            }, 250);
        }        
        
        const show_souvenir = (e)=> {
            data.forEach(item => { 
                if (item.id === e.currentTarget.dataset.id) {
                    souvenir.querySelector('article').innerHTML = item.article;
                    souvenir.classList.add('is-block');

                    let carousel_big_array_x1 = item.carousel_big_x1.split(','),
                        carousel_big_array_x2 = item.carousel_big_x2.split(','),
                        carousel_thumbs_array_x1 = item.carousel_thumbs_x1.split(','),
                        carousel_thumbs_array_x2 = item.carousel_thumbs_x2.split(','),
                        html = '';
                    
                    for (let i = 0; i < carousel_big_array_x1.length; i++) {                        
                        html += '<div class="carousel-cell">';
                        html += '<img data-flickity-lazyload-srcset="'+carousel_big_array_x1[i]+' 1x,' + carousel_big_array_x2[i]+' 2x" data-flickity-lazyload-src='+carousel_big_array_x1[i]+' alt="" class="carousel-cell-image" width="502" height="284" />';
                        html += '</div>';
                    }
                    souvenir.querySelector('.js-souvenirBigCarousel').innerHTML = html;
                    
                    html = '';
                    for (let i = 0; i < carousel_thumbs_array_x1.length; i++) {                        
                        html += '<div class="carousel-cell">';
                        html += '<img data-flickity-lazyload-srcset="'+carousel_thumbs_array_x1[i]+' 1x,' + carousel_thumbs_array_x2[i]+' 2x" data-flickity-lazyload-src='+carousel_thumbs_array_x1[i]+' alt="" class="carousel-cell-image" width="163" />';
                        html += '</div>';
                    }
                    souvenir.querySelector('.js-souvenirThumbsCarousel').innerHTML = html;
                    
                    
                    
                    setTimeout(()=> {
                        disableBodyScroll(souvenir);
                        souvenirCarousel(); 
                        souvenir.classList.add('is-visible');
                        document.addEventListener('click', clickOutside);
                    }, 10);
                }
            });

            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;            
        }

        item.forEach(item => {
            item.addEventListener('click', show_souvenir)
        })
        souvenir.querySelector('.js-close').addEventListener('click', hideSouvenir);
        document.addEventListener('keydown', (evt)=> {
            var isEscape = false;
            "key" in evt ? isEscape = (evt.key == "Escape" || evt.key == "Esc") : isEscape = (evt.keyCode == 27);
            isEscape ? hideSouvenir() : false;
        });
        
        getJSON(process.env.SOUVENIRS_URL);
    }
    
    document.querySelector('#souvenir') ? init() : false;

}, false);
