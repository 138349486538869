import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css"
import isAdult from "./is_adult";

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelector('#age');

    const init = function() {
        const ipt = el.querySelector('input'),
              box = el.querySelectorAll('i');

        const action = function(e) {
            let value = e.currentTarget.value;
            value.length > 0 ? box[value.length - 1].classList.add('is-filled') : false;
            for (let i = 3; i > value.length - 1; i--) {
                box[i].classList.remove('is-filled');
            }
        }

        ipt.focus();
        ipt.addEventListener('keyup', action);

        el.addEventListener('submit', (e) => {

            try{
                if(!isAdult(parseInt(ipt.value))) {
                    // komunikat o niepełnoletności
                    //console.log('Niepełnoletni');
                    Toastify({
                        text: 'Niestety jesteś niepełnoletni, nie możesz wejść na tę stronę.',
                        duration: 5000,
                        className: "error",
                        gravity: "bottom",
                        position: "right",
                        stopOnFocus: true,
                    }).showToast();

                } else {
                    // przekierowanie na stronę
                    window.location.href = el.action+'?v='+Math.random();
                }
            } catch(err) {
                // komunikaty o niepoprawnych danych np: rok z przyszłości albo nie podanie roku
                //console.error(err);
                
                Toastify({
                    text: 'Proszę podać rok urodzenia',
                    duration: 5000,
                    className: "error",
                    gravity: "bottom",
                    position: "right",
                    stopOnFocus: true,
                }).showToast();
            }

            e.stopPropagation();
            e.preventDefault();
        });
    }

    el ? init() : false;

}, false)
