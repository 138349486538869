const Flickity = require('flickity');
require('flickity-as-nav-for');
//import '../../node_modules/flickity/css/flickity.css';
//require('flickity-fade');

document.addEventListener('DOMContentLoaded', ()=> {

    const cards = document.querySelector('.js-cards'),
          faded = document.querySelectorAll('.js-fadedCarousel'),
          pairs = document.querySelectorAll('.js-pairsCarouel'),
          souvenir = document.querySelector('#souvenir');
    
    const cardsCarousel = ()=> {
        let flkty,
            mediaQuery = '(max-width: 1140px)',
            mediaQueryList = window.matchMedia(mediaQuery);
        
        const start = ()=> {
    	    flkty = new Flickity(cards, {
        	    contain: true,
        	    freeScroll: true,
                friction: friction,
                pageDots: 0,
                prevNextButtons: 0,
                wrapAround: false
            })
            
            cards.parentNode.querySelector('.js-next').addEventListener('click', ()=> {
                flkty.next();
            })
            
            cards.parentNode.querySelector('.js-prev').addEventListener('click', ()=> {
                flkty.previous();
            })            
            
            flkty.on('dragStart', () => flkty.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none") );
            flkty.on('dragEnd', () => flkty.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all") );
        }

        mediaQueryList.addEventListener('change', event => {
            event.matches ? start() : flkty.destroy();
        })

        mediaQueryList.matches ? start() : false;
    };

    const friction = .3;

    const fadedCarousel = ()=> {
        let flkty = new Array();
        let gallery_size = false;        

        const carousels = {
/*
            resize: ()=> {
                setTimeout(()=> {
                    for (let i = 0; i < faded.length; i++) {
                        flkty[i].resize();
                    }
                }, 1500);
            },
*/
            start: ()=> {
                for (let i = 0; i < faded.length; i++) {
                    let options = {
                        friction: friction,
                        groupCells: true,
                        lazyLoad: 1,
                        wrapAround: true,
                        pageDots: false,
                        prevNextButtons: 0,
                        on: {
                            ready: ()=> {
                                faded[i].classList.add('extra-options');
                            }
                        }
                    }

                    flkty[i] = new Flickity(faded[i], options);
                    
                    flkty[i].on('dragStart', () => flkty[i].slider.childNodes.forEach(slide => slide.style.pointerEvents = "none") );
                    flkty[i].on('dragEnd', () => flkty[i].slider.childNodes.forEach(slide => slide.style.pointerEvents = "all") );
                    
                    if ( (faded[i].parentNode.querySelector('.prev')) && (faded[i].parentNode.querySelector('.next'))) {
                        let prev = faded[i].parentNode.querySelector('.prev'),
                            next = faded[i].parentNode.querySelector('.next');
                            
                        prev.addEventListener('click', ()=> {
                            flkty[i].previous();
                        })
                        
                        next.addEventListener('click', ()=> {
                            flkty[i].next();
                        })
                    }
                }
            }
        }

        const init = ()=> {
            carousels.start(); 
           // carousels.resize();
        }
        
        init();
        //window.addEventListener('resize', carousels.resize);
    };
    
    const pairsCarousel = ()=> {
        let flkty = new Array(),
            mediaQuery = '(max-width: 640px)',
            mediaQueryList = window.matchMedia(mediaQuery);
        
        const start = ()=> {
            for (let i = 0; i < pairs.length; i++) {
                let options = {
                    cellAlign: 'left',
                    //freeScroll: true,
                    friction: friction,
                    groupCells: true,
                    wrapAround: 0,
                    pageDots: false,
                    prevNextButtons: 0,
                }
    
                flkty[i] = new Flickity(pairs[i], options);
                
                pairs[i].parentNode.querySelector('.next').addEventListener('click', ()=> {
                    flkty[i].next();
                })
                
                pairs[i].parentNode.querySelector('.prev').addEventListener('click', ()=> {
                    flkty[i].previous();
                });
                
                flkty[i].on('dragStart', () => flkty[i].slider.childNodes.forEach(slide => slide.style.pointerEvents = "none") );
                flkty[i].on('dragEnd', () => flkty[i].slider.childNodes.forEach(slide => slide.style.pointerEvents = "all") );
            }
        }
        
        mediaQueryList.addEventListener('change', event => {
            if (event.matches) {
                start()
            } else {
                for (let i = 0; i < pairs.length; i++) {
                    flkty[i].destroy()
                }
            }
        })
        
        mediaQueryList.matches ? start() : false;
    };
    
    const souvenirCarousel = ()=> {
        const souvenir_big = document.querySelector('.js-souvenirBigCarousel'),
              souvenir_small = document.querySelector('.js-souvenirThumbsCarousel');

        window.flkty_big = new Flickity(souvenir_big, {
            contain: true,
            friction: friction,
            lazyLoad: 1,
            pageDots: false,
            prevNextButtons: 0,
            wrapAround: 1
        })

        window.flkty_thumbs = new Flickity(souvenir_small, {
            asNavFor: souvenir_big,
            cellAlign: 'left',
            lazyLoad: 4,
            pageDots: false,
            prevNextButtons: 0,
            wrapAround: 1,
            imagesLoaded: true,
            //draggable: 0,
            //groupCells: true
        })
        
        window.next_souvenir = function() {
            flkty_big.next();
        }
        
        window.prev_souvenir = function() {
            flkty_big.previous();            
        }
        
        window.addEventListener('resize', function() {
            setTimeout(()=> {
                flkty_thumbs.resize();
            }, 100);
        })
        
        if (flkty_thumbs.cells.length <= 3) {
            flkty_thumbs.options.draggable = 0;
            flkty_thumbs.options.groupCells = true;
        }
        
        if (flkty_big) {
            document.querySelector('#souvenir').querySelector('.js-next').addEventListener('click', next_souvenir);          
            document.querySelector('#souvenir').querySelector('.js-prev').addEventListener('click', prev_souvenir);
        }
    };
    
    window.carousels = ()=> {
        cards ? cardsCarousel() : false;
        faded.length > 0 ? fadedCarousel() : false;
        pairs.length > 0 ? pairsCarousel() : false;
        //souvenir ? souvenirCarousel() : false;
        
        // Fix for freescroll

    }
    
    window.souvenirCarousel = souvenirCarousel;

}, false)
