document.addEventListener('DOMContentLoaded', ()=> {
    const el = document.querySelectorAll('.js-timeline');
    const init = () => {
//        console.clear();
        for (let j = 0; j < el.length; j++) { 
            const dates = el[j].querySelectorAll('[data-date]');
    
            for (let i = 0; i < dates.length; i++) {
                let today = new Date().getTime(),
                    date = new Date(dates[i].dataset.date);
                 
               // console.log('today: ' + today + ' / ' + new Date(date.toLocaleDateString('en-EN')).getTime());
                today >= date ? dates[i].classList.add('is-active') : false;
            }
        }
    }
    el.length > 0 ? init() : false;
}, false);
