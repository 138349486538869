import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', ()=> {
/*
    window.runScroll = (el)=> {
        scroll({
            top: document.querySelector(el).offsetTop,
            behavior: "smooth"
        })
    };
*/
    
    window.runScroll = (el)=> {
        window.scrollTo({
            behavior: 'smooth',
            top:
              document.querySelector(el).getBoundingClientRect().top - document.body.getBoundingClientRect().top
          })
    }

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = function(e) {
            let target = e.currentTarget.dataset.target,
                url = location.protocol + "//" + document.domain + "/" + location.pathname.split('/')[1];
          
            document.querySelector(target) ? window.runScroll(target) : window.open(url + target, '_self');
            
            if (target === '#applyForm') {
                document.querySelector('#applyForm').classList.add('is-visible');
            }
            
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
}, false);
