export default function (year, adultAge = 18) {
    const currentDate = new Date();

    if(!year) throw new Error('Proszę podać rok urodzenia');

    if(typeof year !== 'number') throw new Error('Rok urodzenia musi być liczbą');

    const startYear = currentDate.getFullYear() - 100;
    const endYear = currentDate.getFullYear();

    if(year < startYear || year > endYear) throw new Error('Rok powinien być w przedziale ' + startYear + ' - ' + endYear);

    const age = currentDate.getFullYear() - year;

    return age >= adultAge;
}
