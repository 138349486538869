document.addEventListener('DOMContentLoaded', () => {

    //const cover = document.getElementById('cover');
    
    const init = () => {
        //document.documentElement.removeAttribute('style');
        //document.documentElement.classList.add('is-loaded');

        window.carousels();        
        setTimeout(() => {
            window.anims();            
        }, 250);  
    };
    
    window.addEventListener('load', init);
    window.history.pushState('', '', '/');

}, false);
