import css from './sass/style.scss';

require('./js/accordion')
require('./js/age')
require('./js/anims')
require('./js/apply')
require('./js/cards')
require('./js/carousels')
require('./js/glightbox')
require('./js/init')
require('./js/pairs')
require('./js/polyfills')
require('./js/smoothscroll')
require('./js/souvenir')
require('./js/timeline')
require('./js/validate')
require('./js/winners')
