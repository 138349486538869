import { gsap, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded', ()=> {
    const el = document.querySelectorAll('.js-accordion');
    
    const init = (obj)=> {

        const item = obj.getElementsByClassName('js-item');
        
        const toggle = function(ev, section, op) {
            TweenMax.to(section, .3, {
                height: ev,
                immediateRender: false,
                ease: Power3.easeOut,
                onComplete: function() {
                    if (window.swiperFaq) {
                        window.swiperFaq.updateAutoHeight(400);
                    }
                }
            });
            
            TweenMax.to(section, 1, {
                opacity: op,
                ease: Power3.easeOut
            });            
        }

        const action = function(e) {
            const _that = e.currentTarget,
                  _height = 0;

            if (_that.classList.contains('is-rollout')) {
                _that.classList.remove('is-rollout');
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);                
            } else {

                for (let i = 0; i < el.length; i ++) {
                    let item_all = el[i].querySelectorAll('.js-item');
                    
                    for (let j = 0; j < item_all.length; j++) {                        
                        item_all[j].classList.remove('is-rollout');
                        toggle(_height, item_all[j].getElementsByClassName('js-section')[0], 0);
                    }
                }   
                _that.classList.add('is-rollout');
                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
            }
        };
        
        for( let i = 0; i < item.length; i ++ ) {
            item[i].addEventListener('click', action);
            item[i].classList.contains('is-rollout') ? toggle('auto', item[i].getElementsByClassName('js-section')[0], 1) : false;
        }
    };

    if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
            init(el[i]);
        }
    }

}, false);