import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { gsap, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {  
   const el = document.querySelector('#winners');

    const init = ()=> {
        const pair = document.querySelectorAll('.js-pair'); 
        let data; 
        
        const helpers = {
            detach: function(node, target) {
        		let parent = node.parentNode;
        		let next = node.nextSibling;
        	
        		if (!parent) { return; }
        		
        		parent.removeChild(node);	// Detach node from DOM.		
        		target.append(node, next);	// Append
        	},
        	getJSON: (url)=> {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                //xhr.responseType = 'json';
                xhr.onload = ()=> {
                    if (xhr.status === 200) {
                        let json = xhr.response.replace(/(\r\n|\n|\r)/gm, "");
                        data = JSON.parse(json);
                        pair.forEach((item, i) => {
                            item.addEventListener('click', info.action)
                            item.querySelector('.o-box__caption').querySelector('h4').innerHTML = data[i].name_1
                            
                        })                        
                    } else {
                        console.log('error: ' + xhr.status);
                    }
                };
                xhr.send();
            },
            toggle: (ev, section, op, remove) => {
                TweenMax.to(section, 1, {
                    height: ev,
                    immediateRender: false,
                    opacity: op,
                    ease: Power3.easeOut,
                    onComplete: function() {
                        if (remove) {
                            
                        } else {
                            
                        }
                    }
                });
            }
        }
        
        const info  = {
            action: (e) => {
                let _this = e.currentTarget;         
                _this.classList.contains('is-active') ? info.hide(_this) : info.show(_this)
                //e.preventDefault() ? e.preventDefault() : e.preventDefault = false
            },
            
            parse: (e, item) => {
                let html = '';
                html += '<div class="o-article">'
                html += '<figure class="c-winners__figure">'
                html += '<img srcset="'+item.duet_x1+' 1x,' + item.duet_x2+' 2x" src='+item.duet_x1+' alt=""  width="506" height="292" />'
                html += '<figcaption>'
                html += '<header><h2><!--<i class="icon-arrow-right js-closeInfo"></i>-->'+item.name_1+'</h2><p>Wywarzony Duet nr '+item.nr+'</p></header>'
                html += item.figcaption
                html += '</figcaption>'
                html += '</figure>'
                html += item.article
                html += '</div>'
                html += '<button type="button" class="o-btn o-btn--arrow-prev js-closeInfo">Zwiń <i class="icon-arrow-right"></i></button>'
                html += '</div>'
                
                let div = document.createElement("div");
                div.id = "winnersInfo"
                div.className = "c-winners__info"
                div.innerHTML = html

                if (item.id === 'winners-00') {
                    document.querySelector('.o-article--winners').after(div);
                } else {
                    e.closest(".c-winners__other").after(div);
                }
            },

            show: (e) => {
                data.forEach(item => { 
                    if (item.id === e.dataset.id) {        
                        info.hide(e)
                        info.parse(e, item);

                        el.querySelectorAll('.js-closeInfo').forEach(item => {
                            item.addEventListener('click', info.hide)
                        })
                        
                        helpers.toggle('auto', document.querySelector('#winnersInfo'), 1, 0)
                        
                        document.querySelector('#winnersInfo').scrollIntoView({ behavior: "smooth", block: "start" })
                        e.classList.add('is-active')
                        e.querySelector('.js-more').innerHTML = el.dataset.less
                    }
                })
            },

            hide: (e, remove) => {
                if (el.querySelector('.is-active')) {
                    el.querySelector('.is-active').scrollIntoView({ behavior: "smooth", block: "center" });
                    el.querySelector('.is-active').querySelector('.js-more').innerHTML = el.dataset.more;
                    el.querySelector('.is-active').classList.remove('is-active');
                    document.querySelector('#winnersInfo').remove();
                } 
            }           
        }

        //getJSON(process.env.PAIRS_URL);
        helpers.getJSON('winners.json');
    }

    el ? init() : false;

}, false)
