import GLightbox from '../../node_modules/glightbox/dist/js/glightbox.js';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded', function() {
    if (document.querySelectorAll("[href*='youtube']").length > 0) {
        const video = GLightbox({
            selector: "[href*='youtube']"
        });
    }
    
    document.querySelectorAll('.js-glightbox').length > 0 ? GLightbox({ 
        selector: '.js-glightbox',
        draggable: false
    }) : false;
}, false);
