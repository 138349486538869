document.addEventListener('DOMContentLoaded', ()=> {
    
    const cards = document.getElementsByClassName('js-card');
    
    const init = ()=> {
        const action = (e)=> {
            if (e.currentTarget.classList.contains('is-flipped')) {
                e.currentTarget.classList.remove('is-flipped')
            } else {
                e.currentTarget.classList.add('is-flipped');
            }
        }
        
        for (let i = 0; i < cards.length; i++) {
            cards[i].addEventListener('click', action);
        }
    };
   
    cards.length > 0 ? init() : false;

}, false)
