import { gsap  } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {       
        let age = document.querySelector('#age');
        if(age) {
            let tl = gsap.timeline()
            .from(age.querySelector('.o-logo'), {
                duration: 1,
                y: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            })
            .from(age.querySelector('h1'), {
                duration: 1,
                y: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=.9')
            
            .from(".box", {
                duration: 1.5,
                scale: 0,
                autoAlpha: 0,
                y: 140,
                ease: 'elastic.out(.4, .8)',
                stagger: {
                  from: "left",
                  axis: "x",
                  amount: 0.3
                }
            }, '-=0.9')
            
            .from(".o-btn", {
              duration: 1.5,
                y: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1.2');
        }
        
        let heroDuets = document.querySelector('.c-hero--duets');
        if(heroDuets) {
            const textWrapper1 = heroDuets.querySelector('h1');
            textWrapper1.innerHTML = textWrapper1.textContent.replace(/\S/g, "<span class='char'>$&</span>");
            
            const textWrapper2 = heroDuets.querySelector('h2 .primary');
            textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='char'>$&</span>");
          
            textWrapper1.style.visibility = 'visible';
            textWrapper2.style.visibility = 'visible';
            
            let chars1 = textWrapper1.querySelectorAll('.char')
            let chars2 = textWrapper2.querySelectorAll('.char')
        
            let tl = gsap.timeline();
            
            const cfrom = {
                scaleX: -1,
                opacity: 0,
            }
            
            const cto = {
                y: 0,
                opacity: 1,
                stagger: 0.1,
                duration: 1,
                scaleX: 1,
            }
            
            tl
            .fromTo(
                chars1, cfrom, cto, 
            )
            
            .fromTo(
                chars2, cfrom, cto, '-=1.5'
            )
            
            .from(heroDuets.querySelector('h2 .secondary'), {
                duration: 3,
                y: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1.6')
            
            .from(heroDuets.querySelector('.o-btn'), {
                duration: 2,
                x: -20,
                autoAlpha: 0,
                opacity: 1,
                ease: 'elastic.out(1, .8)',
            }, '-=2.5')
            
            .from(heroDuets.querySelector('.hands'), {
                duration: 2,
                autoAlpha: 0,
            }, '-=3.5')
            
            .from(heroDuets.querySelector('.leaf'), {
                x: 20,
                duration: 1,
                autoAlpha: 0,
            }, '-=2.5')
            
            .from(document.querySelector('.c-topbar'), {
                duration: 2,
                yPercent: -100,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=3.5')
            
            .from(document.querySelector('.c-topbar .o-logo'), {
                duration: 2,
                autoAlpha: 0,
            }, '-=2.5')

            gsap.to(".c-hero--duets .leaf", {
                yPercent: 10,
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-hero--duets",
                    scrub: 1,
                    start: 'top top',
                }, 
            });
        }
        
        let heroHome = document.querySelector('.c-hero--home');
        if(heroHome) {
            const textWrapper1 = heroHome.querySelector('h1');
            textWrapper1.innerHTML = textWrapper1.textContent.replace(/\S/g, "<span class='char'>$&</span>");
            
            const textWrapper2 = heroHome.querySelector('h2 .primary');
            textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='char'>$&</span>");
          
            textWrapper1.style.visibility = 'visible';
            textWrapper2.style.visibility = 'visible';
            
            let chars1 = textWrapper1.querySelectorAll('.char')
            let chars2 = textWrapper2.querySelectorAll('.char')
        
            let tl = gsap.timeline();
            
            const cfrom = {
                scaleX: -1,
                opacity: 0,
            }
            
            const cto = {
                y: 0,
                opacity: 1,
                stagger: 0.1,
                duration: 1,
                scaleX: 1,
            }
            
            tl
            .fromTo(
                chars1, cfrom, cto, 
            )
            
            .fromTo(
                chars2, cfrom, cto, '-=1.5'
            )
            
            .from(heroHome.querySelector('h2 .secondary'), {
                duration: 3,
                x: 20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1')
            
            .from(heroHome.querySelector('.o-btn'), {
                duration: 2,
                x: -20,
                autoAlpha: 0,
                opacity: 1,
                ease: 'elastic.out(1, .8)',
            }, '-=2.5')
            
            .from(heroHome.querySelector('.people'), {
                duration: 2,
                autoAlpha: 0,
            }, '-=3.5')
            
            .from(heroHome.querySelector('.leaf'), {
                x: 20,
                duration: 1,
                autoAlpha: 0,
            }, '-=2.5')
            
            .from(document.querySelector('.c-topbar'), {
                duration: 2,
                yPercent: -100,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=3.5')
            
            .from(document.querySelector('.c-topbar .o-logo'), {
                duration: 2,
                autoAlpha: 0,
            }, '-=2.5')

            gsap.to(".c-hero--home .leaf", {
                yPercent: 10,
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-hero--home",
                    scrub: 1,
                    start: 'top top',
                }, 
            });
        }
        
        let leaf_left_top = document.querySelectorAll('.o-leaf--left-top');
        if (leaf_left_top.length > 0) {
            for (let i = 0; i < leaf_left_top.length; i++) {
                gsap.to(leaf_left_top[i], {
                    yPercent: 30,
                    ease: "none",
                    scrollTrigger: {
                        trigger: leaf_left_top[i],
                        scrub: 1,
                        start: 'top bottom',
                    }, 
                });
            }
        }
        
        let leaf_right_top = document.querySelector('.o-leaf--right-top');
        if (leaf_right_top) {
            gsap.to(leaf_right_top, {
                yPercent: 30,
                ease: "none",
                scrollTrigger: {
                    trigger: leaf_right_top,
                    scrub: 1,
                    start: 'top bottom',
                }, 
            });
        }
        
        let leaf_right_bottom = document.querySelector('.o-leaf--right-bottom');
        if (leaf_right_bottom) {
            gsap.to(leaf_right_bottom, {
                yPercent: 30,
                ease: "none",
                scrollTrigger: {
                    trigger: leaf_right_bottom,
                    scrub: 1,
                    start: 'top bottom',
                }, 
            });
        }
        
        let page_404 = document.querySelector('.c-404')
        if (page_404) {
            let tl = gsap.timeline();
            tl.from(document.querySelector('h1'), {
                duration: 2,
                autoAlpha: 0,
                y: 10
            })
            .from(document.querySelector('p'), {
                duration: 2,
                autoAlpha: 0,
                y: 10
            }, '-=1.8')
            .from(document.querySelector('.o-btn'), {
                duration: 2,
                autoAlpha: 0,
                y: 10
            }, '-=1.8')
            .from(document.querySelector('.o-logo'), {
                duration: 2,
                autoAlpha: 0,
            }, '-=1.8')
        }
        
        let souvenirs = document.querySelector('.c-souvenirs .carousel');
        if (souvenirs) {
            gsap.from(souvenirs, {
                scrollTrigger: {
                    opacity: 0,
                    trigger: souvenirs,
                    scrub: 1.5,
                    start: 'top bottom',
                    end: 'center center'
                },      
                xPercent: 20
            });
        }
        
        let video = document.querySelector('.c-video');
        if (video) {
            gsap.from(video.querySelector('.icon-play'), {
                scaleX: 0,
                scaleY: 0,
                duration: 1.5,
                ease: 'elastic.out(1, .8)',
                scrollTrigger: {
                    trigger: video,
                    start: 'top center',
                }, 
            });
        }
     
        gsap.utils.toArray(".a-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete reset",
                },
                autoAlpha: 0,
                duration: 1,
            });
        });
    };

}, false)
