import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { gsap, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {  
   const el = document.querySelector('#pairs');

    const init = ()=> {
        const pair = document.querySelectorAll('.js-pair'); 
        let data; 
        
        const helpers = {
            detach: function(node, target) {
        		let parent = node.parentNode;
        		let next = node.nextSibling;
        	
        		if (!parent) { return; }
        		
        		parent.removeChild(node);	// Detach node from DOM.		
        		target.append(node, next);	// Append
        	},
        	getJSON: (url)=> {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                //xhr.responseType = 'json';
                xhr.onload = ()=> {
                    if (xhr.status === 200) {
                        let json = xhr.response.replace(/(\r\n|\n|\r)/gm, "");
                        data = JSON.parse(json);
                        pair.forEach((item, i) => {
                            item.addEventListener('click', info.action)
                            item.querySelector('.o-box__caption').querySelector('h4').innerHTML = data[i].name_1
                            
                        })
                        
                        const toggler = document.querySelectorAll('.js-voteForm_trigger');
                        toggler.forEach(item => {
                            item.addEventListener('click', info.voteFormToggle)
                        })
                        
                    } else {
                        console.log('error: ' + xhr.status);
                    }
                };
                xhr.send();
            },
            toggle: (ev, section, op, remove) => {
                TweenMax.to(section, 1, {
                    height: ev,
                    immediateRender: false,
                    opacity: op,
                    ease: Power3.easeOut,
                    onComplete: function() {
                        if (remove) {
                            
                        } else {
                            
                        }
                    }
                });
            }
        }
        
        const info  = {
            action: (e) => {
                let _this = e.currentTarget;         
                _this.classList.contains('is-active') ? info.hide(_this) : info.show(_this)
                //e.preventDefault() ? e.preventDefault() : e.preventDefault = false
            },
            
            parse: (e, item) => {
                let html = '';
                html += '<div class="o-article">'
                html += '<figure class="c-pairs__figure">'
                html += '<img srcset="'+item.duet_x1+' 1x,' + item.duet_x2+' 2x" src='+item.duet_x1+' alt=""  width="506" height="292" />'
                html += '<figcaption>'
                html += '<header><h2><!--<i class="icon-arrow-right js-closeInfo"></i>-->'+item.name_1+'</h2><p>Wywarzony Duet nr '+item.nr+'</p></header>'
                html += item.figcaption;
                
                if (new Date().getTime() <= deadline) {
                    html += '<button type="submit" class="o-btn js-openForm_trigger">Oddaj swój głos <i class="icon-arrow-right"></i></button>'
                } else {
                    html += '<p class="deadline">Głosowanie zakończyło się w dn. 15.01.2024'
                }

                html += '</figcaption>'
                html += '</figure>'
                html += '<div id="voteForm_placeholder"></div>'
                html += item.article
                html += '</div>'
                html += '<button type="submit" class="o-btn o-btn--arrow-prev js-closeInfo">Zwiń <i class="icon-arrow-right"></i></button>'
                html += '</div>'
                
                let div = document.createElement("div");
                div.id = "pairInfo"
                div.className = "c-pairs__info"
                div.innerHTML = html
                
                e.closest(".c-pairs__category").after(div);
            },

            show: (e) => {
                data.forEach(item => { 
                    if (item.id === e.dataset.id) {        
                        info.hide(e)
                        info.parse(e, item);
                        info.voteFormCreate(item.id, item.name_2);

                        el.querySelectorAll('.js-closeInfo').forEach(item => {
                            item.addEventListener('click', info.hide)
                        })
                        
                        helpers.toggle('auto', document.querySelector('#pairInfo'), 1, 0)
                        
                        document.querySelector('#pairInfo').scrollIntoView({ behavior: "smooth", block: "start" })
                        e.classList.add('is-active')
                        e.querySelector('.js-more').innerHTML = el.dataset.less
                    }
                })
            },

            hide: (e, remove) => {
                if (el.querySelector('.is-active')) {
                    el.querySelector('.is-active').scrollIntoView({ behavior: "smooth", block: "center" });
                    el.querySelector('.is-active').querySelector('.js-more').innerHTML = el.dataset.more;
                    el.querySelector('.is-active').classList.remove('is-active');
                    document.querySelector('#voteForm').classList.remove('is-filled');
                    document.querySelector('#voteForm').classList.remove('is-message-error');
                    document.querySelector('#voteForm').classList.remove('is-visible');
             
                    helpers.detach(document.querySelector('#voteForm'), document.body);
                    document.querySelector('#pairInfo').remove();
                } 
            },
            
            voteFormToggle: () => {
                const form = document.querySelector('#voteForm');
                helpers.detach(form, document.querySelector('#voteForm_placeholder'));

                form.classList.toggle('is-visible');
                form.classList.remove('is-voted');
                form.reset();
                document.querySelector('#pairInfo').classList.toggle('with-voteForm');
                window.innerWidth > 500 ? document.querySelector('#pairInfo').scrollIntoView({ behavior: "smooth", block: "start" }) : 
                                          form.scrollIntoView({ behavior: "smooth" });
                                          
                for (let i = 0; i < form.querySelectorAll('.form-group').length; i++) {
                    form.querySelectorAll('.form-group')[i].classList.remove('has-success', 'has-error')
                }
                
                for (let i = 0; i < form.querySelectorAll('.form-group').length; i++) {
                    form.querySelectorAll('.form-group')[i].classList.remove('has-success', 'has-error')
                }
            },
            
            voteFormCreate: (id, duet_names) => {
                const form = document.querySelector('#voteForm');
                form.setAttribute('action', 'https://api.wywarzoneduety.pl/api/vote/'+id)
                document.querySelector('#name_2').innerHTML = duet_names;

                if (new Date().getTime() <= deadline) {
                    document.querySelector('.js-openForm_trigger').addEventListener('click', info.voteFormToggle)
                }
                       
                new AirDatepicker('#birthsday', {
                    maxDate: new Date(),
                    view: 'years',
                    minView: 'years',
                    dateFormat: 'yyyy',
                    autoClose: true
                })                
            }
        }
        
        

        //getJSON(process.env.PAIRS_URL);
        helpers.getJSON('pairs.json');
    }

    el ? init() : false;

}, false)
