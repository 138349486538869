import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

document.addEventListener('DOMContentLoaded', () => {    
    const form = document.querySelector('#applyForm');    
    const init = ()=> {
        const toggler = document.querySelectorAll('.js-applyForm_trigger');
        
        const action = (e)=> {
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;            
            form.classList.toggle('is-visible');
            window.innerWidth > 500 ? form.scrollIntoView({ behavior: "smooth", block: "center" }) : 
                                      form.scrollIntoView({ behavior: "smooth" });
        };

        toggler.forEach(item => {
            item.addEventListener('click', action)
        })
               
        new AirDatepicker('#birthsday', {
            maxDate: new Date(),
            view: 'years',
            minView: 'years',
            dateFormat: 'yyyy',
            autoClose: true
        })       
    }

    form ? init() : false;

}, false)
